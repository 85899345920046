import { default as index70mTzoctUsMeta } from "/codebuild/output/src3153651059/src/pages/[slug]/index.vue?macro=true";
import { default as accept_45inviteibiCzGLtC8Meta } from "/codebuild/output/src3153651059/src/pages/accept-invite.vue?macro=true";
import { default as extra_45purchaseqNnNGBPPRxMeta } from "/codebuild/output/src3153651059/src/pages/account/extra-purchase.vue?macro=true";
import { default as index0IVKosXOyOMeta } from "/codebuild/output/src3153651059/src/pages/account/index.vue?macro=true";
import { default as settingsVnL46yP0nSMeta } from "/codebuild/output/src3153651059/src/pages/account/settings.vue?macro=true";
import { default as subscriptiondGQsEFfzQGMeta } from "/codebuild/output/src3153651059/src/pages/account/subscription.vue?macro=true";
import { default as transactions5QdgT8NtF0Meta } from "/codebuild/output/src3153651059/src/pages/account/transactions.vue?macro=true";
import { default as update_45subscriptionZH7UGob5YCMeta } from "/codebuild/output/src3153651059/src/pages/account/update-subscription.vue?macro=true";
import { default as preview9KQ1vHQ2VsMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/emails/[id]/preview.vue?macro=true";
import { default as composeutdVX5LXwzMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/emails/compose.vue?macro=true";
import { default as indexb0f9KNgbLYMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/emails/index.vue?macro=true";
import { default as emailss5gAK3amFSMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/emails.vue?macro=true";
import { default as edit9Y9Q8gQaulMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/events/[id]/edit.vue?macro=true";
import { default as registrationsCXHg5dZhGvMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/events/[id]/registrations.vue?macro=true";
import { default as index0h9TBykNvbMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/events/index.vue?macro=true";
import { default as eventsdWyFRTS0mmMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/events.vue?macro=true";
import { default as filesFOfMpw1qKuMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/files.vue?macro=true";
import { default as gallery3jBscK5gWwMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/gallery.vue?macro=true";
import { default as group_45detailsR0Mpu477KNMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/group-details.vue?macro=true";
import { default as group_45settingsJglt9kVHpSMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/group-settings.vue?macro=true";
import { default as indextx5KTIbKyyMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/index.vue?macro=true";
import { default as memberseTVo9FnOhaMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/members.vue?macro=true";
import { default as edit7d6tWy2rZ1Meta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/news/[id]/edit.vue?macro=true";
import { default as indexz15ZD2CW8dMeta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/news/index.vue?macro=true";
import { default as news4xhCd1DKk0Meta } from "/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/news.vue?macro=true";
import { default as _91slug_93K7gKIB3invMeta } from "/codebuild/output/src3153651059/src/pages/donate/[slug].vue?macro=true";
import { default as indexkUUe6ki0YwMeta } from "/codebuild/output/src3153651059/src/pages/donate/index.vue?macro=true";
import { default as email_45unsubscribeFMTNzeBfwXMeta } from "/codebuild/output/src3153651059/src/pages/email-unsubscribe.vue?macro=true";
import { default as _91slug_93VC4Oq29LLIMeta } from "/codebuild/output/src3153651059/src/pages/events/[slug].vue?macro=true";
import { default as indexlb3SXrW5sAMeta } from "/codebuild/output/src3153651059/src/pages/events/index.vue?macro=true";
import { default as forgot_45passwordGg0lMNRmDnMeta } from "/codebuild/output/src3153651059/src/pages/forgot-password.vue?macro=true";
import { default as filesU1xwWrPvc4Meta } from "/codebuild/output/src3153651059/src/pages/groups/[slug]/files.vue?macro=true";
import { default as galleryqAIF7KhLVlMeta } from "/codebuild/output/src3153651059/src/pages/groups/[slug]/gallery.vue?macro=true";
import { default as indexO7KdyYkkEAMeta } from "/codebuild/output/src3153651059/src/pages/groups/[slug]/index.vue?macro=true";
import { default as shopWFmIJG8z42Meta } from "/codebuild/output/src3153651059/src/pages/groups/[slug]/shop.vue?macro=true";
import { default as _91slug_93a5Pn3H6JvRMeta } from "/codebuild/output/src3153651059/src/pages/groups/[slug].vue?macro=true";
import { default as indexzILCdhkHYQMeta } from "/codebuild/output/src3153651059/src/pages/groups/index.vue?macro=true";
import { default as indexfZc9ly4NLUMeta } from "/codebuild/output/src3153651059/src/pages/index.vue?macro=true";
import { default as joinhd20XtEqyaMeta } from "/codebuild/output/src3153651059/src/pages/join.vue?macro=true";
import { default as loginDaKGYK35TWMeta } from "/codebuild/output/src3153651059/src/pages/login.vue?macro=true";
import { default as confirmationygqkS4321kMeta } from "/codebuild/output/src3153651059/src/pages/membership/confirmation.vue?macro=true";
import { default as index4tnHZXCmoFMeta } from "/codebuild/output/src3153651059/src/pages/membership/index.vue?macro=true";
import { default as _91slug_93iqUZSJjtf7Meta } from "/codebuild/output/src3153651059/src/pages/news/[slug].vue?macro=true";
import { default as indexC5kFVXfqO6Meta } from "/codebuild/output/src3153651059/src/pages/news/index.vue?macro=true";
import { default as reset_45passwordF25NgwnpxzMeta } from "/codebuild/output/src3153651059/src/pages/reset-password.vue?macro=true";
import { default as setup_452faxlSevg323YMeta } from "/codebuild/output/src3153651059/src/pages/setup-2fa.vue?macro=true";
import { default as shopPywaVHjigyMeta } from "/codebuild/output/src3153651059/src/pages/shop.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/codebuild/output/src3153651059/src/pages/[slug]/index.vue")
  },
  {
    name: "accept-invite",
    path: "/accept-invite",
    component: () => import("/codebuild/output/src3153651059/src/pages/accept-invite.vue")
  },
  {
    name: "account-extra-purchase",
    path: "/account/extra-purchase",
    meta: extra_45purchaseqNnNGBPPRxMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/account/extra-purchase.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: index0IVKosXOyOMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/account/index.vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    meta: settingsVnL46yP0nSMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/account/settings.vue")
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptiondGQsEFfzQGMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/account/subscription.vue")
  },
  {
    name: "account-transactions",
    path: "/account/transactions",
    meta: transactions5QdgT8NtF0Meta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/account/transactions.vue")
  },
  {
    name: "account-update-subscription",
    path: "/account/update-subscription",
    meta: update_45subscriptionZH7UGob5YCMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/account/update-subscription.vue")
  },
  {
    name: emailss5gAK3amFSMeta?.name,
    path: "/admin/groups/:slug()/emails",
    meta: emailss5gAK3amFSMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/emails.vue"),
    children: [
  {
    name: "admin-groups-slug-emails-id-preview",
    path: ":id()/preview",
    meta: preview9KQ1vHQ2VsMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/emails/[id]/preview.vue")
  },
  {
    name: "admin-groups-slug-emails-compose",
    path: "compose",
    meta: composeutdVX5LXwzMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/emails/compose.vue")
  },
  {
    name: "admin-groups-slug-emails",
    path: "",
    meta: indexb0f9KNgbLYMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/emails/index.vue")
  }
]
  },
  {
    name: eventsdWyFRTS0mmMeta?.name,
    path: "/admin/groups/:slug()/events",
    meta: eventsdWyFRTS0mmMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/events.vue"),
    children: [
  {
    name: "admin-groups-slug-events-id-edit",
    path: ":id()/edit",
    meta: edit9Y9Q8gQaulMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/events/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-events-id-registrations",
    path: ":id()/registrations",
    meta: registrationsCXHg5dZhGvMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/events/[id]/registrations.vue")
  },
  {
    name: "admin-groups-slug-events",
    path: "",
    meta: index0h9TBykNvbMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/events/index.vue")
  }
]
  },
  {
    name: "admin-groups-slug-files",
    path: "/admin/groups/:slug()/files",
    meta: filesFOfMpw1qKuMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/files.vue")
  },
  {
    name: "admin-groups-slug-gallery",
    path: "/admin/groups/:slug()/gallery",
    meta: gallery3jBscK5gWwMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/gallery.vue")
  },
  {
    name: "admin-groups-slug-group-details",
    path: "/admin/groups/:slug()/group-details",
    meta: group_45detailsR0Mpu477KNMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/group-details.vue")
  },
  {
    name: "admin-groups-slug-group-settings",
    path: "/admin/groups/:slug()/group-settings",
    meta: group_45settingsJglt9kVHpSMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/group-settings.vue")
  },
  {
    name: "admin-groups-slug",
    path: "/admin/groups/:slug()",
    meta: indextx5KTIbKyyMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/index.vue")
  },
  {
    name: "admin-groups-slug-members",
    path: "/admin/groups/:slug()/members",
    meta: memberseTVo9FnOhaMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/members.vue")
  },
  {
    name: news4xhCd1DKk0Meta?.name,
    path: "/admin/groups/:slug()/news",
    meta: news4xhCd1DKk0Meta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/news.vue"),
    children: [
  {
    name: "admin-groups-slug-news-id-edit",
    path: ":id()/edit",
    meta: edit7d6tWy2rZ1Meta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/news/[id]/edit.vue")
  },
  {
    name: "admin-groups-slug-news",
    path: "",
    meta: indexz15ZD2CW8dMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/admin/groups/[slug]/news/index.vue")
  }
]
  },
  {
    name: "donate-slug",
    path: "/donate/:slug()",
    component: () => import("/codebuild/output/src3153651059/src/pages/donate/[slug].vue")
  },
  {
    name: "donate",
    path: "/donate",
    component: () => import("/codebuild/output/src3153651059/src/pages/donate/index.vue")
  },
  {
    name: "email-unsubscribe",
    path: "/email-unsubscribe",
    component: () => import("/codebuild/output/src3153651059/src/pages/email-unsubscribe.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/codebuild/output/src3153651059/src/pages/events/[slug].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/codebuild/output/src3153651059/src/pages/events/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/codebuild/output/src3153651059/src/pages/forgot-password.vue")
  },
  {
    name: _91slug_93a5Pn3H6JvRMeta?.name,
    path: "/groups/:slug()",
    component: () => import("/codebuild/output/src3153651059/src/pages/groups/[slug].vue"),
    children: [
  {
    name: "groups-slug-files",
    path: "files",
    component: () => import("/codebuild/output/src3153651059/src/pages/groups/[slug]/files.vue")
  },
  {
    name: "groups-slug-gallery",
    path: "gallery",
    component: () => import("/codebuild/output/src3153651059/src/pages/groups/[slug]/gallery.vue")
  },
  {
    name: "groups-slug",
    path: "",
    component: () => import("/codebuild/output/src3153651059/src/pages/groups/[slug]/index.vue")
  },
  {
    name: "groups-slug-shop",
    path: "shop",
    component: () => import("/codebuild/output/src3153651059/src/pages/groups/[slug]/shop.vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    component: () => import("/codebuild/output/src3153651059/src/pages/groups/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3153651059/src/pages/index.vue")
  },
  {
    name: "join",
    path: "/join",
    component: () => import("/codebuild/output/src3153651059/src/pages/join.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/codebuild/output/src3153651059/src/pages/login.vue")
  },
  {
    name: "membership-confirmation",
    path: "/membership/confirmation",
    component: () => import("/codebuild/output/src3153651059/src/pages/membership/confirmation.vue")
  },
  {
    name: "membership",
    path: "/membership",
    meta: index4tnHZXCmoFMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/membership/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/codebuild/output/src3153651059/src/pages/news/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/codebuild/output/src3153651059/src/pages/news/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/codebuild/output/src3153651059/src/pages/reset-password.vue")
  },
  {
    name: "setup-2fa",
    path: "/setup-2fa",
    meta: setup_452faxlSevg323YMeta || {},
    component: () => import("/codebuild/output/src3153651059/src/pages/setup-2fa.vue")
  },
  {
    name: "shop",
    path: "/shop",
    component: () => import("/codebuild/output/src3153651059/src/pages/shop.vue")
  }
]